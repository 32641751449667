<template>
  <div id="app">
    <Home :mobile="isMobile"></Home>
  </div>
</template>

<style lang="scss">
#app {
   :root {
    --primary: #eef2f3;
    --secondary: black;
  }
  
  background-color: #eef2f3;
  color: #232323;
  font-family: 'Roboto Mono', monospace;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    box-sizing: border-box;
  }

  input, textarea, button {
    border-radius: 0;
    font-family: 'Roboto Mono', monospace;
    font-size: 1rem;
  }

  input:focus, textarea:focus {
      outline: none;
  }

  .fade-enter-active, .fade-leave-active {
        transition: opacity 0.5s ease;
    }

  .fade-enter-from, .fade-leave-to {
      opacity: 0;
  }
}

@media (max-width: 1450px) {
  #app {
    font-size: 12px;
  }
}
</style>

<script>
import Home from '@/views/Home.vue'

export default {
  name: 'App',
  components: {Home},
  mounted() {
    //console.log(this.$route)
    if (window.innerWidth <= 1024) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  data() {
    return {
      isMobile: false
    }
  }
}
</script>
