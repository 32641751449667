<template>
    <li :style="cssVars"
        @click="selectTrack"
        ref="track">{{this.song.name}} - {{this.song.title}}</li>
</template>

<style lang="scss" scoped>
    li {
        border: 1px solid;
        border-color: var(--secondary);
        margin-bottom: 1rem;
        padding: 1rem;
    }

    li:hover {
        background-color: var(--secondary);
        color: var(--primary);
        cursor: pointer;
    }

    li.selected {
        background-color: var(--secondary);
        color: var(--primary);
    }
</style>

<script>
export default {
    name: 'PlaylistSong',
    props: ['song', 'colors', 'index', 'selected', 'small'],
    methods: {
        selectTrack: function() {
            this.$emit('track-selected', this.index, true);
        }
    },
    watch: {
        selected: function() {
            this.$refs['track'].classList.remove('selected');
            if (this.index == this.selected) this.$refs['track'].classList.toggle('selected')
        }
    },
    computed: {
        cssVars: function() {
            return {
                '--primary': this.colors.primary,
                '--secondary': this.colors.secondary
            }
        }
    }
}
</script>