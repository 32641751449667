<template>
    <div class="track-song">
        <img v-bind:src="this.song.cover">
        <div class="title-display">
            <h1>{{this.song.title}}</h1>
            <h2>{{this.song.name}}</h2>
        </div>
        <div class="controls">
            <i @click="moveTrackUp" class="fal fa-chevron-circle-up"></i>
            <i @click="moveTrackDown" class="fal fa-chevron-circle-down"></i>
            <i @click="deleteTrack" class="fal fa-times"></i>
        </div>
    </div>
</template>

<style lang="scss">
    .track-song {
        align-items: center;
        background-color: white;
        border: 1px solid white;
        display: flex;
        margin-bottom: 1rem;
        width: 100%;

        img {
            height: 5rem;
            width: 5rem;
        }

        h1 {
            font-size: 1.25rem;
        }

        h2 {
            font-size: 1rem;
        }

        .title-display {
            padding: 1rem;
        }

        .controls {
            flex-grow: 1;
            padding: 1rem;
            text-align: right;

            i {
                color: rgba(0,0,0,0.5);
                margin: 0.25rem;
            }

            i:hover {
                color: black;
                cursor: pointer;
            }

            .fa-times {
                margin-left: 0.75rem;
            }
        }

    }

    @media (max-width: 1024px) {
        .track-song {
            h1 {
                font-size: 1rem;
            }

            h2 {
                font-size: 0.75rem;
            }

            i {
                font-size: 1rem;
            }
        }
    }
</style>

<script>
export default {
    name: 'SongDisplay',
    props: ['song', 'index', 'primaryColor', 'secondaryColor'],
    methods: {
        moveTrackUp: function() {
            this.$emit('move-song-up', this.index);
        },
        moveTrackDown: function() {
            this.$emit('move-song-down', this.index);
        },
        deleteTrack: function() {
            this.$emit('del-song', this.index);
        }
    }
}
</script>