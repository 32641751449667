<template>
    <div :style="cssVars" class="playlist-view">
        <div class="playlist-info">
            <h1>{{this.info.name}}</h1>
            <h2 :style="cssVars">Created by <a class="user-display">{{this.info.user}}</a> &bull; {{this.songCount}} &bull; {{this.vibeTense}} &bull; <a @click="$emit('open-share')"><i class="fal fa-share"></i><span>Share</span></a></h2>
            <h2 class="owner-controls" v-if="user.username == info.user"><a @click="editList"><i class="fas fa-pen fa-fw"></i>Edit Playlist</a> | <a @click="$emit('modal', 'delete-list')"><i class="fas fa-times fa-fw"></i>Delete Playlist</a></h2>
            <p>{{this.info.desc}}</p>
            <div class="social-options" ref="social-options">
                <h2>
                    <a @click="toggleVibes"><i class="fas fa-sunglasses"></i><span v-html="vibeDisplay"></span></a> | 
                    <a @click="toggleFavorite"><i class="far fa-album-collection"></i><span v-html="favoritedDisplay"></span></a>
                </h2>
            </div>
        </div>
        <ul class="track-list">
            <li class='titles'>
                <div class="fav"></div>
                <div class="title">Title</div>
                <div class="artist">Artist</div>
                <div class="bc"></div>
            </li>
            <TracklistSong
                v-for="(song, index) in list" :key="song.id" 
                :index="index" :song="song" :colors="colors" :selected="selected"
                @selected-track="selectTrack(index)"></TracklistSong>
        </ul>
    </div>
</template>

<style lang="scss">
    .playlist-view {

        h1 {
            font-size: 3rem;
            margin-bottom: 0.5rem;
        }

        h2 {
            a {
                color: var(--secondary);
                //text-decoration: underline;
            }

            a:hover {
                cursor: pointer;
            }

            i {
                margin-right: 0.25rem;
            }
        }

        h2.share {
            margin: 0.5rem 0 1rem 0;

            a {
                text-decoration: none;
            }

            a:hover {
                text-decoration: underline;
            }
        }

        h2.owner-controls {
            margin-top: 0.5rem;

            a:hover {
                cursor: pointer;
            }

            i {
                margin-right: 0.25rem;
            }
        }

        h3 {
            font-size: 0.75rem;
            margin-top: 0.25rem;
        }

        p {
            margin: 1.25rem 0;
        }

        a.user-display {
            text-decoration: underline;
        }

        .social-options {
            margin-bottom: 1rem;

            a {
                text-decoration: underline;
            }

            a:hover {
                cursor: pointer;
            }

            i {
                margin-right: 0.5rem;
            }

            h2 {
                line-height: 1.6;
            }
        }

        .nope {
            opacity: 0.3;

            a:hover {
                cursor: not-allowed;
            }
        }

        .track-list {
            li {
                display: flex;
                justify-content: space-around;
                padding: 0.5rem;
                width: 100%;
            }

            li.titles {
                border-bottom: 1px solid;
                border-color: var(--secondary);

                div {
                    text-align: left;
                }
            }
            .fav {
                text-align: center;
                width: 5%;
            }

            .title {
                width: 40%;
            }

            .artist {
                width: 40%;
            }

            .bc a {
                text-decoration: none;
                color: var(--secondary);
            }

            .bc i {
                margin-left: 0.5rem;
            }
        }
    }

    @media (max-width: 1024px) {
        .playlist-info {
            padding: 0.5rem;

            h3 {
            font-size: 0.5rem;
        }
        }

        h1 {
            font-size: 2rem;
        }        

        .track-list {
            font-size: 0.8rem;
            padding-bottom: 9vh;
        }

        .social-options {
            margin-bottom: 0.5rem;
        }
    }
</style>

<script>
import TracklistSong from '@/components/TracklistSong.vue';

export default {
    name: 'PlaylistView',
    components: {TracklistSong},
    props: ['list', 'info', 'colors', 'vibes', 'selected', 'user', 'logged'],
    methods: {
        selectTrack: function(index) {
            this.$emit('song-selected', index);
        },
        checkIfFavorited: function() {
            if (this.user.username !== undefined) {
                this.user.favorites.forEach(fav => {
                    //console.log(fav);
                    if (fav == this.info.uniq) {
                        this.favorited = true;
                    }
                })
            }
        },
        checkIfVibed: function() {
            if (this.logged == 2) {
                //console.log('checking if vibed...');
                //console.log(this.user.vibed);
                this.user.vibed.forEach(vibe => {
                    if (vibe == this.info.uniq) {
                        this.vibed = true;
                    }
                })
            }
        },
        toggleFavorite: function() {
            if (this.logged == 2) {
                let uniq = this.info.uniq;

                if (this.favorited == false) {
                    this.$emit('update-favs', uniq, true);
                    this.favorited = true;
                } else {
                    this.$emit('update-favs', uniq, false);
                    this.favorited = false;
                }
            }
        },
        toggleVibes: function() {
            if (this.logged == 2) {
                let uniq = this.info.uniq;

                if (this.vibed == false) {
                    this.$emit('update-vibes', uniq, true);
                    this.vibed = true;
                } else {
                    this.$emit('update-vibes', uniq, false);
                    this.vibed = false;
                }
            }
        },
        loadList(list) {
            this.$emit('load-list', list);
        },
        editList() {
            this.$emit('change-state', 'editor');
            this.$router.push('/make');
        }
    },
    computed: {
        cssVars: function() {
            return {
                '--primary': this.colors.primary,
                '--secondary': this.colors.secondary
            }
        },
        songCount: function() {
            let tense = 'song'
            if (this.list.length > 1) tense = 'songs'

            return `${this.list.length} ${tense}`;
        },
        vibeTense: function() {
            let tense = `${this.vibes} vibes`
            if (this.vibes == 1) tense = `${this.vibes} vibe`;
            return tense;
        },
        favoritedDisplay: function() {
                if (!this.favorited) {
                    return 'Add playlist to favorites'
                } else {
                    return 'Remove playlist from favorites'
                }
                
        },
        vibeDisplay: function() {
            if (!this.vibed) {
                return 'Give vibes'
            } else {
                return "Vibed"
            }
        },
        routeUniq: function() {
            return this.$route.params.uniq
        }
    },
    watch: {
        user: function() {
            this.checkIfFavorited();
            this.checkIfVibed();
        },
        logged: function() {
            if (this.logged !== 2) {
                this.$refs['social-options'].classList.add('nope');
            } else {
                this.$refs['social-options'].classList.remove('nope');
            }
        },
        routeUniq: function() {
            this.loadList(this.$route.params.uniq);
        }
    },
    mounted() {
        this.loadList(this.$route.params.uniq);
        this.$emit('change-state', 'playlist');
        this.$emit('clear-vibes');

        if (this.logged !== 2) {
            this.$refs['social-options'].classList.add('nope');
        } else {
            this.checkIfFavorited();
            this.checkIfVibed();
        }
    },
    data() {
        return {
            favorited: false,
            vibed: false,
            isOwner: false
        }
    }
}
</script>