<template>
    <div :style="cssVars" class="login-form">
        <h1>Username</h1>
        <input v-model="user">
        <h1>Password</h1>
        <input type="password" v-model="pass">
        <ul v-if="this.errs.length > 0" :style="cssVars" class="errors">
            <li v-for="err in errs" :key="err.id">&bull; {{err}}</li>
        </ul>
        <a @click="loginAccount" class="btn-login" ref="login">Login</a>
        <div class="sign-up">Don't have an account? <a @click="changeToRegister">Register</a></div>
      </div>
</template>

<style lang="scss" scoped>
    .login-form {
        align-items: center;
        background-color: var(--primary);
        display: flex;
        flex-direction: column;
        margin: 2rem 0;
        padding: 1rem;

        h1 {
            color: var(--secondary);
            font-size: 1rem;
            margin-bottom: 0.25rem;
            width: 100%;
        }

        input {
          background-color: rgba(0,0,0,0);
          border: 0;
          border-bottom: 1px solid;
          border-bottom-color: var(--secondary);
          color: var(--secondary);
          margin-right: 1rem;
          margin-bottom: 1rem;
          width: 100%;
        }

        input:focus {
          border-bottom: 1px solid black;
          outline: none;
        }

        a {
          color: var(--secondary);
          text-align: center;
          width: 100%;
        }

        .errors {
            background: var(--secondary);
            color: var(--primary);
            width: 100%;

            li {
                margin: 0.25rem;
            }
        }

        .btn-login {
          background-color: var(--primary);
          border:1px solid black;;
          border-color: var(--secondary);
          color: var(--secondary);
          margin: 1rem;
          padding: 0.75rem;
        }

        .btn-login:hover {
            background-color: var(--secondary);
            color: var(--primary);
            cursor: pointer;
        }

        .sign-up {
            font-size: 0.75rem;

            a {
                text-decoration: underline;
            }

            a:hover {
                cursor: pointer;
            }
        }

        i {
            margin-left: 0.5rem;
        }
    }

    @media (max-width: 1024px) {
        .login-form {
            h1 {
                font-size: 1rem;
            }
        }
    }
</style>

<script>
import axios from 'axios';

export default {
    name: 'LoginForm',
    props: ['colors'],
    computed: {
        cssVars: function() {
            if (this.$route.name == 'playlist') {
                return {'--primary': this.colors.primary, '--secondary': this.colors.secondary}
            } else {
                return {}
            }
        }
    },
    methods: {
        loginAccount: function() {
            this.errs = [];
            this.$refs['login'].innerHTML = `<i class="fal fa-spinner-third fa-spin"></i>`
            //console.log('logging in...');
            axios.post('https://bndcmpr.co/api/login', {user: this.user, pass: this.pass})
            .then(res => {
                this.$refs['login'].innerHTML = `Login`
                if (res.data.error) {
                    this.errs.push(res.data.error)
                } else {
                    //console.log(res.data);
                    this.$emit('logged-in', res.data);
                }
            })
        },
        changeToRegister: function() {
            this.$emit('change-to-reg', 0)
        }
    },
    data() {
        return {
            user: '',
            pass: '',
            errs: [],
        }
    }
}
</script>