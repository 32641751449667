<template>
    <div class="user-profile">
        <h1>{{user.username}}</h1>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
export default {
    name: 'Profile',
    props: ['user']
}
</script>