import Vue from 'vue'
import VueRouter from 'vue-router'
import PlaylistCreator from '@/views/PlaylistCreator.vue'
import PlaylistView from '@/views/PlaylistView.vue'
import Profile from '@/views/Profile.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: PlaylistCreator,
  },
  {
    path: '/make',
    name: 'creator',
    component: PlaylistCreator
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/embed/:uniq',
    name: 'embed',
  },
  {
    path: '/:uniq',
    name: 'playlist',
    component: PlaylistView
  }
  /*{
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue')
  }*/
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
