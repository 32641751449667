<template>
    <div :style="cssVars" class="audio-player" ref="player">
        <progress v-if="!mobile || wideOpen"
            v-bind:value="this.progress"
            @mouseup="timelineSeek" @mousemove="timelineScrobble" @mouseover="seeking = true" @mouseleave="seeking = false"
            max="100"
            ref="progress-bar"></progress>
        <div v-show="this.url" class="controls" ref="controls">
            <!--<i @click="toggleShuffle" class="fad fa-random fa-fw" ref="shuffle"></i>-->
            <i @click="prevSong" class="fal fa-backward fa-fw"></i>
            <i @click="togglePlay" class="fal fa-play fa-fw play-button" ref="playButton"></i>
            <i @click="nextSong" class="fal fa-forward fa-fw"></i>
            <!--<i @click="toggleRepeat" class="fad fa-repeat fa-fw" ref="repeat"></i>-->
        </div>
    </div>
</template>

<style lang="scss" scoped>
    progress {
        height: 0.5rem;
        width: 100%;
    }

    progress[value] {
        appearance: none;
    }

    progress[value]::-webkit-progress-bar {
        background-color: var(--primary);
        border: 1px solid var(--secondary);
        border-radius: 1rem;
    }

    progress[value]::-webkit-progress-value {
        background-color: var(--secondary);
        border-radius: 1rem;
        
    }

    .controls {
        align-items: center;
        display: flex;
        font-size: 2rem;
        justify-content: flex-end;
        padding: 1rem;
        text-align: center;
        width: 100%;

        i {
            margin: 0 0.5rem;
        }

        i:hover {
            cursor: pointer;
        }

        i.fa-play {
            margin: 0 1rem;
        }

        i.fa-random, i.fa-repeat {
            font-size: 1.5rem;
            opacity: 0.5;
        }

        i.fa-random:hover, i.fa-repeat:hover {
            opacity: 1;
        }

        i.fa-random {
            margin-right: 3rem;
        }

        i.fa-repeat {
            margin-left: 3rem;
        }
    }

    @media (max-width: 1024px) {
        .controls {
            padding: 0;

            .fa-random, .fa-repeat, .fa-backward, .fa-forward {
                display: none;
            }

            .fa-play, .fa-pause {
                font-size: 2rem;
            }
        }
    }

    .mobile-open {
        progress {
            margin-bottom: 2rem;
            width: 95vw;
        }

        .controls {
            justify-content: center;
        }

        .fa-random, .fa-repeat, .fa-backward, .fa-forward {
            display: block;
        }
    }

    .embed {
        .fa-backward, .fa-forward {
            display: block;
        }
    }
</style>

<script>
//import axios from 'axios';

export default {
    name: 'AudioPlayer',
    props: ['url', 'play', 'list', 'current', 'open', 'state', 'colors', 'mobile', 'wideOpen'],
    methods: {
        playSong: function() {
            //this.$refs.player.play()
            this.audio.play()
            .then(() => {
                this.isPlaying = true;
                if ('mediaSession' in navigator) {
                    navigator.mediaSession.metadata = new window.MediaMetadata({
                        title: this.list[this.current].title,
                        artist: this.list[this.current].name,
                        artwork: [
                        { src: this.list[this.current].cover,   sizes: '96x96',   type: 'image/jpeg' },
                        { src: this.list[this.current].cover, sizes: '128x128', type: 'image/jpeg' },
                        { src: this.list[this.current].cover, sizes: '192x192', type: 'image/jpeg' },
                        { src: this.list[this.current].cover, sizes: '256x256', type: 'image/jpeg' },
                        { src: this.list[this.current].cover, sizes: '384x384', type: 'image/jpeg' },
                        { src: this.list[this.current].cover, sizes: '512x512', type: 'image/jpeg' },
                        ]
                    });
                }
            })
        },
        pauseSong: function() {
            //this.$refs.player.pause();
            this.audio.pause();
            this.isPlaying = false;
        },
        togglePlay: function() {
            if (this.isPlaying) {
                this.pauseSong();
            } else {
                this.playSong();
            }
        },
        timelineSeek: function() {
            this.audio.currentTime = this.audio.duration * (this.progress / 100)
        },
        timelineScrobble: function(event) {
            this.progress = ((event.clientX - this.$refs['progress-bar'].getBoundingClientRect().left) / this.$refs['progress-bar'].getBoundingClientRect().width) * 100          
        },
        nextSong: function() {
            this.$emit('next-song', this.isPlaying);
        },
        prevSong: function() {
            this.$emit('prev-song', this.isPlaying, this.repeat);
        },
        toggleRepeat: function() {
            if (this.repeat == false) {
                this.repeat = true
                this.$refs['repeat'].style.opacity = "1";
            } else {
                this.repeat = false;
                this.$refs['repeat'].style.opacity = "0.5";
            }            
            console.log(this.repeat);
        },
        toggleShuffle: function() {
            if (this.shuffle == false) {
                this.shuffle = true
                this.$refs['shuffle'].style.opacity = "1";
            } else {
                this.shuffle = false;
                this.$refs['shuffle'].style.opacity = "0.5";
            }    
        },
    },
    watch: {
        url: function() {
            //this.$refs.player.load();
            this.audio.src = this.url;
            this.audio.type = 'audio/mpeg';
            this.audio.load();
            this.currentSong = {};
            if (this.play) this.playSong();
        },
        isPlaying: function() {
            if (this.isPlaying) {
                this.$refs['playButton'].classList.remove('fa-play');
                this.$refs['playButton'].classList.add('fa-pause');
            } else {
                this.$refs['playButton'].classList.remove('fa-pause');
                this.$refs['playButton'].classList.add('fa-play');
            }
        },
        open: function() {
            this.$refs['player'].classList.toggle('mobile-open');
        },
        list: function() {
            if (this.list == '') {
                this.$refs['progress-bar'].style.display = 'none';
            } else {
                this.$refs['progress-bar'].style.display = "block";
            }
        }
    },
    computed: {
        cssVars: function() {
            if (this.$route.name == 'playlist') {
                return {'--primary': this.colors.primary, '--secondary': this.colors.secondary}
            } else {
                return {'--primary': '#eef2f3', '--secondary': 'black'}
            }
        }
    },
    mounted() {
        //console.log(`Full mobile: ${this.wideOpen}`)
        this.audio.addEventListener('ended', () => {
            this.nextSong();
        })

        this.audio.addEventListener('timeupdate', () => {
            if (!this.seeking) {
                this.progress = (this.audio.currentTime / this.audio.duration) * 100;
            }
        })

        if ('mediaSession' in navigator) {
            navigator.mediaSession.setActionHandler("play", () => {
                this.playSong();
                navigator.mediaSession.playbackState = "playing";
            });

            navigator.mediaSession.setActionHandler("pause", () => {
                this.pauseSong();
                navigator.mediaSession.playbackState = "paused";
            });

            navigator.mediaSession.setActionHandler("previoustrack", () => {
                this.prevSong();
            });

            navigator.mediaSession.setActionHandler("nexttrack", () => {
                this.nextSong();
            });
        }
    },
    data() {
        return {
            audio: new Audio(),
            isPlaying: false,
            progress: 0,
            repeat: false,
            shuffle: false,
            shuffledSongs: [],
            seeking: false
        }
    }
}
</script>