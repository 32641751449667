<template>
    <li :style="cssVars" ref="track">
        <!--<div class="fav"><i class="fal fa-heart"></i></div>-->
        <div @click="selectTrack" class="title">{{this.song.title}}</div>
        <div @click="selectTrack" class="artist">{{this.song.name}}</div>
        <div class="bc"><a :href="this.song.url" target="_blank">buy<i class="fab fa-bandcamp"></i></a></div>
    </li>
</template>

<style lang="scss" scoped>
    li:hover {
        background-color: var(--secondary);
        color: var(--primary);
        cursor: pointer;

        a {
            color: var(--primary);
        }

        i {
            color: var(--primary);
        }

        .bc a {
            color: var(--primary);
        }
    }

    .selected {
        background-color: var(--secondary);
        color: var(--primary);

        a {
            color: var(--primary);
        }

        i {
            color: var(--primary);
        }

        .bc, .bc a {
            color: var(--primary);
        }
    }
</style>

<script>
export default {
    name: 'TracklistSong',
    props: ['song', 'colors', 'index', 'selected'],
    computed: {
        cssVars: function() {
            return {
                '--primary': this.colors.primary,
                '--secondary': this.colors.secondary
            }
        }
    },
    methods: {
        selectTrack: function() {
            //console.log(event);
            this.$emit('selected-track', this.index);
        },
        
    },
    watch: {
        selected: function() {
            //console.log(`Selected song: ${this.selected}`);
            this.$refs['track'].classList.remove('selected');
            if (this.index == this.selected) this.$refs['track'].classList.toggle('selected')
        }
    }
}
</script>