<template>
    <div class="playlist-creator">
        <h1 class="page-title">{{this.pageTitle}}</h1>
        <div class="playlist-info">
            <h1>playlist name</h1>
            <input v-model="playlistName">
            <h1>description ({{this.playlistDesc.length}}/140 characters)</h1>
            <textarea v-model="playlistDesc" maxlength="140"></textarea>
            <h1>colors</h1>
            <ul>
                <li>primary:<input v-model="primaryColor" type="color"></li>
                <li>secondary:<input v-model="secondaryColor" type="color"></li>
            </ul>
        </div>
        <div class="song-list">
            <SongDisplay 
                v-for="(song, index) in songList"
                :song="song" :index="index" :key="song.id" :primaryColor="primaryColor" :secondaryColor="secondaryColor"
                @move-song-up="moveSongUp"
                @move-song-down="moveSongDown"
                @del-song="delSong"></SongDisplay>
        </div>
        <SongEntry @playlist-add-song="addSong" :primaryColor="primaryColor" :secondaryColor="secondaryColor"></SongEntry>
        <ul v-if="hasErrors" class="errors">
            <li v-for="err in errs" :key="err.id">{{err}}</li>
        </ul>
        <div @click="makePlaylist" class="make-playlist">{{this.buttonText}}</div>
    </div>
</template>

<style lang="scss">
    .playlist-creator {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        padding: 1rem;
        width: 100%;

        .page-title {
            font-size: 2rem;
            margin-bottom: 1rem;
        }

        input {
            background-color: rgba(0,0,0,0);
            border: 0;
            border-bottom: 1px solid rgba(0,0,0,0.2);
            margin-right: 1rem;
            width: 100%;
        }

        input:focus {
            border-bottom: 1px solid black;
            outline: none;
        }

        textarea {
            border: 1px solid rgba(0,0,0,0.2);
            font-family: 'Roboto Mono', monospace;
            font-size: 1rem;
            resize: none;
            width: 100%;
        }

        textarea:focus {
            border-color: black;
            outline: none;
        }

        .playlist-info {
            background: white;
            border: 1px solid white;
            margin-bottom: 2rem;
            padding: 1rem;

            h1 {
                margin-bottom: 0.25rem;
            }

            input {
                margin-bottom: 1rem;
            }

            input[type=color] {
                border-bottom: 0;
                height: 1.5rem;
                margin: 0;
                padding: 0;
                width: 2rem;
            }

            textarea {
                margin-bottom: 1rem;
            }

            ul {
                border: 1px solid black;
                display: flex;
                padding: 0.5rem;

                li {
                    align-items: center;
                    display: flex;
                }

                li:first-of-type {
                    margin-right: 1rem;
                }
            }
        }

        .song-list {
            margin-bottom: 1rem;
            width: 100%;
        }
    }

    .make-playlist {
        border: 1px solid black;
        margin-top: 1rem;
        padding: 1rem;
        text-align: center;
    }

    .make-playlist:hover {
        background-color: black;
        color: white;
        cursor: pointer;
    }

    .errors {
        background-color: white;
        margin-top: 1rem;
        padding: 0.5rem;

        li {
            margin: 1rem 0;
        }
    }

    @media (max-width: 1024px) {
        .playlist-creator {
        padding-bottom: 7rem;
        
            h1 {
                font-size: 1rem;
            }

            textarea {
            border: 1px solid black;
        }

            ul li {
                font-size: 0.75rem
            }

            .make-playlist {
                background-color: black;
                color: white;
            }
        }
    }
</style>

<script>
import SongDisplay from '@/components/SongDisplay.vue';
import SongEntry from '@/components/SongEntry.vue';
import arrayMove from 'array-move';
import axios from 'axios';
import validator from 'validator';

export default {
    name: 'PlaylistCreator',
    props: ['user', 'state', 'list', 'info', 'colors'],
    components: {
        SongEntry,
        SongDisplay
    },
    methods: {
        addSong(song) {
            this.songList.push(song);
            //console.log(this.songList);
        },
        moveSongUp(song) {
            if (song !== 0) {
                arrayMove.mutate(this.songList, song, (song - 1));
                //console.log(this.songList);
            }
        },
        moveSongDown(song) {
            if (song < this.songList.length) {
                arrayMove.mutate(this.songList, song, (song + 1));
                console.log(this.songList);
            }
        },
        delSong(song) {
            this.songList.splice(song, 1);
            //console.log(this.songList);
        },
        makePlaylist() {
            //console.log('submitting');
            let errs = [];

            if (validator.isEmpty(this.playlistName)) errs.push('A name is required for a playlist.');
            if (this.songList.length < 1) errs.push('You need songs for your playlist.');
            if (!this.user.username) errs.push('You need to login before you make a playlist.');
            
            if (errs.length < 1) {
                //success
                
                
                if (this.state == 'editor') {
                    axios.post('https://bndcmpr.co/api/update/playlist', {
                        uniq: this.playlistUniq,
                        user: this.user.username,
                        name: this.playlistName,
                        desc: this.playlistDesc,
                        colors: this.playlistColors,
                        tracks: this.songList
                    })
                    .then(res => {
                        //console.log(res);
                        this.$router.push({name: 'playlist', params: {uniq: res.data}})
                        this.$emit('list-submit', res.data);
                    })
                } else {
                    axios.post('https://bndcmpr.co/api/add/playlist', {user: this.user.username, name: this.playlistName, desc: this.playlistDesc, colors: this.playlistColors, tracks: this.songList
                    })
                    .then(res => {
                        //console.log(res);
                        this.$router.push({name: 'playlist', params: {uniq: res.data}})
                        this.$emit('list-submit', res.data);
                    })
                }
            } else {
                //fail
                this.errs = errs;
            }
        },
        changeColor() {
            this.$emit('change-color', this.playlistColors);
        },
        updateInfo() {
            this.$emit('update-info', this.playlistInfo);
        },
    },
    computed: {
        playlistColors: function() {
            return {primary: this.primaryColor, secondary: this.secondaryColor}
        },
        playlistInfo: function() {
            if (this.state == 'editor') {
                return {name: this.playlistName, desc: this.playlistDesc, uniq: this.playlistUniq}
            } else {
                return {name: this.playlistName, desc: this.playlistDesc}
            }
            
        },
        hasErrors: function() {
            if (this.errs.length > 0) {
                return true
            } else {
                return false
            }
        },
        pageTitle: function() {
            if (this.state == 'editor') {
                return 'Edit your Playlist';
            } else {
                return 'Make a Playlist'
            }
        },
        buttonText: function() {
            if (this.state == 'editor') {
                return 'update playlist'
            } else {
                return 'create playlist'
            }
        }
    },
    watch: {
        playlistName: function() {
            this.updateInfo()
        },
        playlistDesc: function() {
            this.updateInfo()
        },
        primaryColor: function() {
            this.changeColor();
        },
        secondaryColor: function() {
            this.changeColor();
        },
        songList: function() {
            this.$emit('update-list', this.songList);
        },

    },
    mounted() {
        if (this.state == 'editor') {
            console.log(this.info);
            this.songList = this.list;
            this.playlistName = this.info.name;
            this.playlistDesc = this.info.desc;
            this.primaryColor = this.colors.primary;
            this.secondaryColor = this.colors.secondary;
            this.playlistUniq = this.info.uniq;
        } else {
            this.$emit('clear-list');
            this.$emit('change-state', 'creator')
        }

        //console.log(this.state);
    },
    data() {
        return {
            songList: [],
            playlistName: '',
            playlistDesc: '',
            playlistUniq: '',
            primaryColor: '#eef2f3',
            secondaryColor: '#000000',
            errs: [],
            loggedIn: false,
        }
    }
}
</script>