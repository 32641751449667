<template>
    <div :style="cssVars" class="register-form">
        <h1>Username</h1>
        <input v-model="user" class="input-user">
        <h1>Password</h1>
        <input type="password" v-model="pass">
        <h1>Confirm Password</h1>
        <input type="password" v-model="conf">
        <ul v-if="this.errs.length > 0" :style="cssVars" class="errors">
            <li v-for="err in errs" :key="err.id">&bull; {{err}}</li>
        </ul>
        <a :style="cssVars" @click="registerAccount" class="btn-signup" ref="signup">Sign Up</a>
        <div class="login">Already have an account? <a @click="changeToLogin">Login</a></div>
      </div>
</template>

<style lang="scss" scoped>
    .register-form {
        align-items: center;
        background-color: var(--primary);
        display: flex;
        flex-direction: column;
        margin: 2rem 0;
        padding: 1rem;

        h1 {
            color: var(--secondary);
            font-size: 1rem;
            margin-bottom: 0.25rem;
            width: 100%;
        }

        input {
          background-color: rgba(0,0,0,0);
          border: 0;
          border-bottom: 1px solid;
          border-bottom-color: var(--secondary);
          color: var(--secondary);
          margin-right: 1rem;
          margin-bottom: 1rem;
          width: 100%;
        }

        input:focus {
          border-bottom: 1px solid black;
          outline: none;
        }

        .input-user {
            margin-bottom: 3rem;
        }

        a {
          color: var(--secondary);
          text-align: center;
          width: 100%;
        }

        .errors {
            background: var(--secondary);
            color: var(--primary);
            width: 100%;

            li {
                margin: 0.25rem;
            }
        }

        .btn-signup {
          background-color: var(--primary);
          border:1px solid black;;
          border-color: var(--secondary);
          color: var(--secondary);
          margin: 1rem;
          padding: 0.75rem;
        }

        .btn-signup:hover {
            background: var(--secondary);
            color: var(--primary);
            cursor: pointer;
        }

        .login {
            font-size: 0.75rem;

            a {
                text-decoration: underline;
            }

            a:hover {
                cursor: pointer;
            }
        }

        i {
            margin-left: 0.5rem;
        }
    }

    @media (max-width: 1024px) {
        h1 {
            font-size: 1rem;
        }
    }
</style>

<script>
import axios from 'axios';
import validator from 'validator';

export default {
    name: 'RegisterForm',
    props: ['colors'],
    methods: {
        registerAccount: function() {
            this.errs = [];
            if (validator.isEmpty(this.user)) this.errs.push(`Must fill out a username`);
            if (!validator.isAlphanumeric(this.user)) this.errs.push('Username must not contain spaces or special characters')
            if (validator.isEmpty(this.pass)) this.errs.push(`Password is empty`);
            if (this.pass.length <= 6) this.errs.push(`Password must be longer than 6 characters`);
            if (validator.isEmpty(this.conf)) this.errs.push('Password confirmation is empty');
            if (this.pass !== this.conf) this.errs.push(`Passwords don't match`);


            if (this.errs.length == 0) {
                console.log('regging account...');
                this.$refs['signup'].innerHTML = `<i class="fal fa-spinner-third fa-spin"></i>`
                axios.post('https://bndcmpr.co/api/register', {user: this.user, pass: this.pass})
                .then(res => {
                    if (res.data.status == "error") {
                        this.errs.push(res.data.message);
                    } else {
                        axios.post('https://bndcmpr.co/api/login', {user: this.user, pass: this.pass})
                        .then(res => {
                            this.$emit('logged-in', res.data);
                        })
                    }

                })
            }
            
        },
        changeToLogin: function() {
            this.$emit('change-to-log', 1);
        }
    },
    computed: {
        cssVars: function() {
            if (this.$route.name == 'playlist') {
                return {'--primary': this.colors.primary, '--secondary': this.colors.secondary}
            } else {
                return {}
            }
        }
    },
    data() {
        return {
            user: '',
            pass: '',
            conf: '',
            errs: []
        }
    }
}
</script>