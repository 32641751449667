<template>
  <div :style="cssVars" class="home" ref="home">
    <Info
      v-if="state !== 'embed'"
      :colors="colors" :mobile="mobile" :show="mobileShow" :user="user" :logged="loginStatus" :state="state" :userLists="userLists"
      @user-logged-in="userLoggedIn" @user-logged-out="userLogout" @toggle-mobile="mobileToggle" @close-menu="closeMobileMenu"
      @change-login="changeLoginStatus" ref="info"></Info>
    <div v-if="state !== 'embed'" :style="cssVars" class="content scroll" ref="content">
      <div class="modal" ref="modal">
        <div v-if="modalStatus == 'delete-list'">
          <h1>Are you sure you want to delete this playlist?</h1>
          <p>This cannot be undone and it will 100% be gone forever.</p>
          <a @click="deletePlaylist">Delete</a>
          <a @click="hideModal">Cancel</a>
        </div>
        <div v-if="modalStatus == 'share-list'">
          <h1>Share Playlist</h1>
          <h2>Permalink</h2>
          <div class="permalink"><span>https://bndcmpr.co/{{this.playlistInfo.uniq}}</span></div>
          <h2>Embed</h2>
          <div class="embed-style">
            <a @click="toggleEmbedOrientation('landscape')" class="selected" ref="btn-landscape"><i class="fas fa-rectangle-wide fa-fw"></i></a><a @click="toggleEmbedOrientation('portrait')" ref="btn-portrait"><i class="fas fa-rectangle-portrait fa-fw"></i></a>
            {{this.embedText}}
          </div>
          <input v-model="embedIframeHtml" @focus="$event.target.select()">
          <a @click="hideModal">Close</a>
        </div>
      </div>
      <div v-if="mobile" class="mobile-header"><i @click="mobileToggle" class="fal fa-bars"></i></div>
      <router-view
        :colors="colors" :list="songList" :info="playlistInfo" :vibes="vibes" :selected="selected" :user="user" :logged="loginStatus" :state="state"
        @change-color="changeColor" @update-list="updateSongList" @update-info="updatePlaylistInfo" @list-submit="listSubmit"
        @song-selected="playSong" @change-state="changeState" @clear-list="clearList" @update-favs="updateFavorites" @load-list="getList"
        @update-vibes="updateVibes" @modal="showModal" @clear-vibes="clearVibes" @open-share="showModal('share-list'); embedOrientation = false"></router-view>
    </div>
    <div class="menu scroll" ref="player-menu">
      <Playlist 
        :state="state" :list="songList" :colors="colors" :info="playlistInfo" :toPlay="toPlay" :mobile="mobile"
        @track-selected="playSong" @load-list="getList"></Playlist>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .home {
    background-color: var(--primary);
    display: flex;
    height: 100vh;

    .modal {
      align-items: center;
      background-color: rgba(0,0,0,0.5);
      color: black;
      display: none;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -10000;

      div {
        background-color: white;
        display: flex;
        flex-direction: column;
        max-width: 33%;
        padding: 2rem;

        h1 {
          font-weight: bold;
          margin: 0 0 0.5rem 0;
        }

        h2 {
          margin: 1rem 0 0.5rem 0;
        }

        p {
          margin-bottom: 1rem;
        }

        a {
          border: 1px solid black;
          margin: 1rem 0;
          padding: 1rem;
          text-align: center;
          width: 100%;
        }

        a:hover {
          background-color: black;
          color: white;
          cursor: pointer;
        }

        input {
          border: 1px solid black;
          box-shadow: none;
          font-size: 1rem;
          padding: 0.25rem;
        }

        .copy {
            background-color:white;
            border: 1px solid black;
            margin-left: 1rem;
            padding: 0.25rem;
          }

        .copy:hover {
          background-color: black;
          color: white;
          cursor: pointer;
        }

        .permalink {
          display: block;
          margin: 0 0 1rem 0;
          padding: 0;
          
          span {
            text-decoration: underline;
          }
        }

        .embed-style {
          align-items: center;
          flex-direction: row;
          margin-bottom: 0.5rem;
          padding: 0;

          a {
            margin: 0 1rem 0 0;
            padding: 0.25rem;

            i {
              font-size: 1.5rem;
            }
          }

          a.selected {
            background-color: black;
            color: white;
          }
        }
      }
    }

    .content {
      background-color: var(--primary);
      border-left: 1px solid var(--secondary);
      color: var(--secondary);
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: scroll;
      padding: 1rem;
      width: 50%;

      .mobile-header {
        font-size: 2rem;
        padding: 1rem;
      }
    }

    .menu {
      background-color: var(--primary);
      //border-left: 1px solid var(--secondary);
      display: flex;
      height: 100%;
      overflow-y: scroll;
      width: 25%;
    }
  }

  .scroll::-webkit-scrollbar-track {
    background-color: var(--primary);
  }

  .scroll::-webkit-scrollbar {
    width: 0.25rem;
    background-color: var(--primary);
  }

  .scroll::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
  }

  @media (max-width: 1024px) {
    .home {
      display: flex;
      font-size: 16px;
      flex-direction: column;
      height: 100%;
      padding-bottom: 12vh;

      .modal {
        div {
          max-width: 85%;

          h1 {
            font-size: 1rem;
          }
        }
      }

      .content {
        border: none;
        font-size: 12px;
        height: 91vh;
        padding: 0.25rem;
        width: 100%;
      }

      .menu {
        display: flex;
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: fixed;
        height: 9vh;
        width: 100%;
        z-index: 1000;
      }
    }
  }

  .embed {
    align-items: center;
    display: flex;
    height: 100% !important;
    justify-content: center;
    left: 0;
    width: 100vw !important;
    top: 0;
  }
</style>

<script>
  import Info from '@/components/Info.vue';
  import Playlist from '@/components/Playlist.vue';
  import axios from 'axios';

  export default {
    name: 'Home',
    props: ['mobile'],
    components: {
      Info, Playlist
    },
    methods: {
      getList: function(list) {
        //console.log(`getting list: https://bndcmpr.co/api/list/${list}`);
        axios.get(`https://bndcmpr.co/api/list/${list}`)
        .then(res => {
          //console.log(res.data);
          //check tracks to make sure they're all valid;
          let localList = JSON.parse(res.data.tracks);
          //console.log(localList);

          localList.forEach((song, index) => {
            let audioCheck = new Audio;
            audioCheck.src = song.file;

            audioCheck.addEventListener('error', () => {
              console.log(`big trouble trying to load ${song.url}`);

              axios.post('https://bndcmpr.co/api/gimme/track', {url: song.url})
              .then(res => {
                  audioCheck.src = res.data.url;
                  localList[index].file = res.data.url;
              })          
            })
          })
          this.songList = localList;
          this.colors = JSON.parse(res.data.colors);
          this.playlistInfo = {name: res.data.name, desc: res.data.description, user: res.data.creator, uniq: res.data.uniqid}
          this.vibes = res.data.vibes;
        })
      },
      changeColor: function(colors) {
        this.colors = colors;
      },
      updateSongList: function(list) {
        this.songList = list;
      },
      updatePlaylistInfo: function(info) {
        this.playlistInfo = info;
      },
      playSong: function(song) {
        this.selected = song;
        this.toPlay = song;
      },
      listSubmit: function(list) {
        //console.log('hi! List submitted!')
        this.refreshUserPlaylists();
        this.state = 'playlist'
        this.getList(list);
      },
      checkUser: function() {
        //console.log('checking user...');
        axios.defaults.withCredentials = true;
        axios.get('https://bndcmpr.co/api/active')
        .then(res => {
          //console.log(res.data);
          if (res.data.username) {
            this.userLoggedIn(res.data);
          } else {
            //console.log('user logged out');
            this.loginStatus = 1;
          }
        })
      },
      userLoggedIn: function(user) {
        //console.log(user);
        this.user = user;

        if (this.user.favorites == '') {
          this.user.favorites = [];
        } else {
          this.user.favorites = JSON.parse(this.user.favorites);
        }

        if (this.user.vibed == '') {
          this.user.vibed = [];
        } else {
          this.user.vibed = JSON.parse(this.user.vibed);
        }

        if (this.loginStatus == 0) {
          this.mobileShow = true;
        } else {
          this.mobileShow = false;
        }

        this.loginStatus = 2;
        this.refreshUserPlaylists();
        //console.log('local user:');
        //console.log(this.user);
      },
      userLogout: function() {
        //console.log('logging out..');
        axios.defaults.withCredentials = true;
        axios.get('https://bndcmpr.co/api/logout')
        .then(() => {
        //console.log(res);
        this.user = {};
        this.userLists = [];
        this.loginStatus = 1;
        });
      },
      mobileToggle: function() {
        //console.log('toggling mobile...');
        if (this.mobileShow) {
          this.mobileShow = false;
        } else {
          this.mobileShow = true;
        }
      },
      closeMobileMenu: function() {
        this.mobileShow = false;
      },
      changeLoginStatus: function(status) {
        this.loginStatus = status;
      },
      changeState: function(state) {
        this.state = state;
      },
      clearList: function() {
        //console.log('clearing list...')
        this.songList = [];
        this.colors = {};
        this.playlistInfo = {};
      },
      updateFavorites: function(uniq, add) {
        //console.log('syncing favorites...')
        if (add) {
          //console.log('adding...');
          this.user.favorites.push(uniq);
        } else {
          //console.log('removing...');
          let i = this.user.favorites.indexOf(uniq);
          if (i > -1) {
              this.user.favorites.splice(i, 1);
          }
        }

        //console.log(this.user.favorites);
        axios.post('https://bndcmpr.co/api/favorites', {user: this.user.username, favs: JSON.stringify(this.user.favorites)})
        .then(() => {
          this.$refs['info'].getFavorites();
        })
      },
      clearVibes: function() {
        //console.log('vibes cleared...');
        this.vibes = 0;
      },
      updateVibes: function(uniq, add) {
        if (add) {
          this.vibes++;
          this.user.vibed.push(uniq)
        } else {
          this.vibes--;
          let i = this.user.vibed.indexOf(uniq);
          if (i > -1) {
          this.user.vibed.splice(i, 1);
          }
        }

        axios.post('https://bndcmpr.co/api/vibe', {uniq: uniq, vibe: add, updated: this.user.vibed, user: this.user.username})
        .then(() => {
          //console.log(res);
        })

      },
      refreshUserPlaylists: function() {
        if (this.loginStatus == 2) {
          axios.post('https://bndcmpr.co/api/gimme/userlists', {user: this.user.username})
          .then(res => {
            if (res.data.error == undefined) {
              this.userLists = res.data
            } else {
              this.userLists = [];
            }
          })
        }
      },
      showModal: function(status) {
        this.modalStatus = status;

        this.$refs['modal'].style.display = 'flex';
        this.$refs['modal'].style.zIndex = '10000';
      },
      hideModal: function() {
        this.modalStatus = '';
        this.$refs['modal'].style.display = 'none';
        this.$refs['modal'].style.zIndex = '-10000';
      },
      deletePlaylist: function() {
        axios.post('https://bndcmpr.co/api/delete/playlist', {uniq: this.playlistInfo.uniq})
        .then(res => {
          console.log(res)
          this.refreshUserPlaylists();
          this.$router.push('/make')
          this.hideModal();
        });
      },
      toggleEmbedOrientation: function(orientation) {
        this.$refs['btn-landscape'].classList.remove('selected');
        this.$refs['btn-portrait'].classList.remove('selected');
        if (orientation == 'portrait') {
          this.embedOrientation = true;
          this.$refs['btn-portrait'].classList.add('selected');
        } else if (orientation == 'landscape') {
          this.embedOrientation = false;
          this.$refs['btn-landscape'].classList.add('selected');
        }
      }
    },
    computed: {
      cssVars: function() {
          if (this.$route.name == 'playlist') {
              return {'--primary': this.colors.primary, '--secondary': this.colors.secondary}
          } else {
              return {'--primary': '#eef2f3', '--secondary': 'black'}
          }
      },
      embedIframeHtml: function() {
        if (!this.embedOrientation) {
          return `<iframe src="https://bndcmpr.co/embed/${this.playlistInfo.uniq}?orientation=landscape" style="height:91px;width:100%;border:none;" title="${this.playlistInfo.name}"></iframe>`;
        } else {
          return `<iframe src="https://bndcmpr.co/embed/${this.playlistInfo.uniq}?orientation=portrait" style="height:600px;width:350px;border:none;" title="${this.playlistInfo.name}"></iframe>`;
        }
      },
      embedText: function() {
          if (!this.embedOrientation) {
              return 'landscape'
          } else {
              return 'portrait'
          }
      }
    },
    mounted() {
      this.checkUser();

      if (this.$route.name == "embed") {
        this.mobileShow = false;
        this.state = 'embed';
        this.$refs['player-menu'].classList.add('embed');
        this.$refs['home'].style.height = "100vh";
        this.$refs['home'].style.padding = "0";
      } else {
        if (this.$route.params.uniq) {
          this.mobileShow = false;
          this.state = 'playlist';
        }
      }
    },
    data() {
      return {
        songList: [],
        colors: {},
        playlistInfo: {},
        vibes: 0,
        state: 'creator',
        toPlay: -1,
        selected: -1,
        loginStatus: -1,
        user: {},
        mobileShow: true,
        playerMode: false,
        userLists: [],
        modalStatus: '',
        embedOrientation: ''
      }
    }
  }
</script>
