<template>
    <div :style="absorbStyle" class="info" ref="info">
      <div v-if="mobile & logged == 2" class="mobile-header"><i @click="closeMenu" class="fal fa-times"></i></div>
      <h1 class="title" :style="absorbHeader"><i class="fal fa-caravan-alt"></i>BNDCMPR</h1>
      <h2 class="sub">a playlist platform for bandcamp</h2>
      <RegisterForm v-if="logged == 0"
        :colors="colors"
        @change-to-log="changeLogin" @logged-in="userLoggedIn"></RegisterForm>
      <LoginForm 
        v-if="logged == 1"
        :colors="colors"
        @logged-in="userLoggedIn"
        @change-to-reg="changeLogin"></LoginForm>
      <div v-if="logged == 2" :style="cssVars" class="menu">
        <span>Howdy, {{user.username}}</span>
        <ul>
          <router-link to="/make" @click.native="closeMenu"><i class="fal fa-list-music"></i>Make Playlist</router-link>
          <!--<router-link to="/favs/list" @click.native="closeMenu"><i class="fal fa-album-collection"></i>Favorited Playlists</router-link>-->
          <!--<a><i class="fad fa-record-vinyl"></i>Favorited Songs</a>-->
          <!--<router-link to="/profile" @click.native="closeMenu"><i class="fal fa-head-side"></i>Profile</router-link>-->
          <a @click="userLogout"><i class="fal fa-sign-out"></i>Logout</a>
          <!--<router-link>My Playlists</router-link>
          <router-link>Favorites</router-link>-->
        </ul>
        <h1>Playlists</h1>
        <ul class="user-lists scroll">
            <li @click="$router.push({name: 'playlist', params: {uniq: list.uniqid}}); closeMenu()" v-for="list in userLists" :key="list.id"><i class="fal fa-list-music fa-fw"></i>{{list.name}}</li>        
            <li @click="$router.push({name: 'playlist', params: {uniq: fav.uniqid}}); closeMenu()" v-for="fav in favoritedLists" :key="fav.id"><i class="fal fa-album-collection fa-fw"></i>{{fav.name}}</li>
        </ul>
      </div>
      <section class="news"></section>
      <section class="changelog"></section>
      <div class="footer">
        <span>Created with love, coffee &amp; Lexapro by <a :style="absorbStyle" href="https://twitter.com/lonbeshiri">Lon Beshiri</a>.</span>
      </div>
    </div>
</template>

<style lang="scss" scoped>
    .info {
      background-color: white;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      height: 100%;
      width: 25%;

      h1 {
        color: #408ea3;
        font-size: 4rem;

        i {
          font-size: 0.80em;
        }
      }

      h2 {
        font-size: 1.25rem;
      }

      ul {
        display: flex;
        flex-direction: column;
        margin: 2rem 0;

        li {
          margin: 1rem 0;
        }
      }

      .menu {
        height: auto;
        margin: 2rem 0;
        width: 100%;

        h1 {
          color: var(--secondary);
          font-size: 1rem;
          font-weight: bold;
        }

        a {
          color: var(--secondary);
          margin-bottom: 0.5rem;
          text-decoration: none;
          width: auto;
        }

        a:hover {
          cursor: pointer;
          text-decoration: underline;
        }

        span {
          width: 100%;
        }

        li {
          cursor: pointer;
        }

        li:hover {
          text-decoration: underline;
        }

        i {
          margin-right: 0.5rem;
        }

        .user-lists {
          margin: 0;
          max-height: 25vh;
          overflow-y: scroll;
          padding: 1rem 0;


          li {
            margin: 0.25rem 0;
            padding: 0;
          }
        }
      }

      .footer {
        align-items: flex-end;
        display: flex;
        flex-grow: 1;
        font-size: 0.75rem;
        line-height: 1.666;

        a {
          color: var(--secondary);
        }
      }
    }

    .scroll::-webkit-scrollbar-track {
      background-color: var(--primary);
    }

    .scroll::-webkit-scrollbar {
      width: 0.25rem;
      background-color: var(--primary);
    }

    .scroll::-webkit-scrollbar-thumb {
      background-color: var(--secondary);
    }

    @media (max-width: 1450px) {
        .info {
          h1 {
              font-size: 3rem;
          }

          h2 {
              font-size: 0.75rem;
          }
        }
    }

    /*@media (max-width: 1170px) {
        .info { 
          .title {
              font-size: 3rem !important;
          } 

          .sub {
              font-size: 0.75rem;
          }
        }
    }*/

    @media (max-width: 1024px) {
      .info {
        background-color: white;
        height: 91vh;
        left: 0;
        margin-top: 0;
        position: fixed;
        top: 0;
        transition: all 0.4s ease;
        width: 100%;
        z-index: 100;

        .mobile-header {
          font-size: 2rem;
          text-align: right;
          width: 100%;
        }

        h1 {
          font-size: 4rem;
        }

        h2 {
          font-size: 1rem;
        }
      }

      .closed {
        transform: translateX(-100vw);
      }
    }
</style>

<script>
import RegisterForm from '@/components/RegisterForm.vue';
import LoginForm from '@/components/LoginForm.vue';
import axios from 'axios';

export default {
    name: 'Info',
    props: ['colors', 'mobile', 'show', 'user', 'logged', 'state', 'userLists'],
    components: {RegisterForm, LoginForm},
    methods: {
        userLoggedIn: function(user) {
          this.$emit('user-logged-in', user);
        },
        userLogout: function() {
          this.$emit('user-logged-out');
        },
        changeLogin: function(status) {
          this.$emit('change-login', status);
        },
        cssVars: function() {
            if (this.$route.name == 'playlist') {
                return {'--primary': this.colors.primary, '--secondary': this.colors.secondary}
            } else {
                return {}
            }
        },
        toggleShow: function() {
          if (this.show) {
            this.$refs['info'].classList.remove('closed');
          } else {
            this.$refs['info'].classList.add('closed');
          }
        },
        closeMenu: function() {
          this.$emit('close-menu');
        },
        getFavorites: function() {
            this.favoritedLists = [];
            if (this.logged == 2) {
              //console.log(this.user.favorites)
              this.user.favorites.forEach(fav => {
                  axios.get(`https://bndcmpr.co/api/list/${fav}`)
                  .then(res => {
                    if (res.data.name !== undefined) {
                      res.data.tracks = JSON.parse(res.data.tracks);
                      this.favoritedLists.push(res.data);
                    }
                  })
              })
            }
        }
    },
    watch: {
      show: function() {
        this.toggleShow();
      },
      logged: function() {
        //this.getUserLists();
        this.getFavorites();
      }
    },
    computed: {
      absorbStyle: function() {
        if (this.$route.name == 'playlist') {
          return {backgroundColor: this.colors.primary , color: this.colors.secondary}
        } else {
          return {backgroundColor: '#eef2f3', color: 'black'}
        }
      },
      absorbHeader: function() {
        if (this.$route.name == 'playlist') {
          return {color: this.colors.secondary}
        } else {
          return {color: '#408ea3'}
        }
      },
      favorites: function() {
        return this.user.favorites
      }
    },
    mounted() {
      this.toggleShow();
      this.getFavorites();
    },
    data() {
      return {
        favoritedLists: [],
      }
    }
}
</script>