<template>
    <div :style="cssVars" class="playlist" ref="playlist">
        <div class="mobile-close"><i @click="toggleFullMobilePlayer" class="fal fa-angle-down"></i></div>
        <h1 class="title" v-if="!this.playerMode & !this.mobile & this.orientation !== 'landscape'">{{info.name}}</h1>
        <p v-if="!this.playerMode & !this.mobile & this.orientation !== 'landscape'">{{info.desc}}</p>
        <img @click="toggleFullMobilePlayer" v-if="this.selected.cover" :style="cssVars" :src="this.selected.cover" ref="cover">
        <div @click="toggleFullMobilePlayer" class="track-title" ref="title">
            <h2>{{this.selected.track}}</h2>
            <h3>{{this.selected.artist}}</h3>
        </div>
        <div class="track-controls">
            <AudioPlayer 
                @next-song="nextSong" @prev-song="prevSong"
                :url="this.selected.file" :play="this.selected.play" :list="list" :current="currentSong" :open="fullMobilePlayer" :state="state" 
                :colors="colors" :mobile="mobile" :wideOpen="fullMobilePlayer"></AudioPlayer>
        </div>
        <ul v-if="orientation !== 'landscape'" class="track-list">
            <PlaylistSong 
                v-for="(song, index) in list" :key="song.id" 
                :index="index" :song="song" :colors="colors" :selected="currentSong"
                @track-selected="trackSelected"></PlaylistSong>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
    .playlist {
        display: flex;
        background-color: var(--primary);
        color: var(--secondary);
        flex-direction: column;
        height: 100%;
        padding: 1rem;
        width: 100%;

        .mobile-close {
            display: none;
            font-size: 2rem;
            margin-bottom: 2rem;
            text-align: left;
            width: 100%;
        }

        .preview {
            border: 1px solid black;
            display: inline;
            padding: 0.25rem;
            position: absolute;
            right: 0;
            top: 0;
        }

        .track-controls {
            display: flex;
            justify-content: center;
        }

        h1 {
            font-size: 2rem;
        }

        h2 {
            font-size: 2rem;
            margin: 0.5rem 0;
        }

        h3 {
            margin-bottom: 0.5rem;
        }

        h2, h3 {
            text-align: center;
            width: 100%;
        }

        p {
            margin: 1rem 0;
        }

        img {
            border: 1px solid;
            border-color: var(--secondary);
            display: block;
            width: 100%;
            height: auto;
            //padding: 3rem;
        }

        ul {
            width: 100%;

            /*li {
                border: 1px solid black;
                margin-bottom: 1rem;
                padding: 1rem;
            }*/

        }
    }

    @media (max-width: 1450px) {
        .playlist {
            h2 {
                font-size: 1.5rem;
            }
        }
    }

    @media (max-width: 1024px) {
        .playlist {
            align-items: center;
            border-top: 1px solid var(--secondary);
            display: flex;
            flex-direction: row;
            margin-top: 0;
            padding: 0;
            transition: all 0.4s ease;
            z-index: 1000;

            img {
                border: 0;
                height: 100%;
                width: auto;
            }

            .track-title {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                padding-left: 1rem;

                h2, h3 {
                    text-align: left;
                }

                h2 {
                    font-size: 1rem;
                }

                h3 {
                    font-size: 0.75rem;
                }
            }

            .track-list {
                display: none;
            }
        }
    }

    .wide-open {
        //margin-top: -91vh;
        transform: translateY(-91vh);
        display: flex;
        flex-direction: column;
        left: 0;
        height: 100vh;
        padding: 1rem;
        position: fixed;

        .mobile-close {
            padding-top: 1rem;
            display: block;
        }

        img {
            height: auto;
            margin-bottom: 1rem;
            width: 100%;
        }

        .track-title {
            align-self: flex-start;
            flex-grow: 0;
            margin-bottom: 2rem;
            padding: 0;

            h2 {
                font-size: 1.5rem;
                font-weight: bold;
            }

            h3 {
                font-size: 1rem;
            }
        }
    }

    .big-pad {
        padding-top: 7rem;
    }

    .player-embed-landscape {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-top: 0;
        padding: 0;
        
        img {
            border: none;
            height: 100%;
            width: auto;
        }

        .track-title {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            padding-left: 1rem;

            h2, h3 {
                text-align: left;
            }

            h2 {
                font-size: 1rem;
            }

            h3 {
                font-size: 0.75rem;
            }
        }

        progress {
            display: none;
        }
    }

    .player-embed-portrait {
        align-items: center;
        border: none;
        display: flex;
        flex-direction: column;
        margin-top: 0;
        overflow-y: scroll;
        padding: 1rem;
        
        img {
            border: none;
            height: auto;
            max-width: 100%;
        }

        .track-title {
            margin-bottom: 1rem;

            h2 {
                font-size: 1.5rem;
            }

            h3 {
                font-size: 1rem;
            }

            h2, h3 {
                text-align: center;
            }
        }

        .track-controls {
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;
        }

        h1 {
            font-size: 2rem;
        }

        h2 {
            font-size: 2rem;
            margin: 0.5rem 0;
        }

        h3 {
            margin-bottom: 0.5rem;
        }

        h2, h3 {
            text-align: center;
            width: 100%;
        }

        p {
            margin: 1rem 0;
        }

        img {
            border: 1px solid;
            border-color: var(--secondary);
            display: block;
            width: 100%;
            height: auto;
            //padding: 3rem;
        }

        ul {
            width: 100%;
        }

        .track-list {
            display: block;
        }
    }
</style>

<script>
import PlaylistSong from '@/components/PlaylistSong.vue';
import AudioPlayer from '@/components/AudioPlayer.vue';
import InApp from 'detect-inapp';

export default {
    name: 'Playlist',
    components: {PlaylistSong, AudioPlayer},
    props: ['preview', 'list', 'colors', 'info', 'toPlay', 'mobile', 'state'],
    methods: {
        trackSelected: function(track, play) {
            if (this.list.length > 0) {
                let check = track;
                if (check == -1 || check == undefined) track = 0
                
                this.selected = {track: this.list[track].title, artist: this.list[track].name, cover: this.list[track].cover, file: this.list[track].file, play: play, active: true}
                this.currentSong = track;

                if (check > -1) {
                    this.$emit('track-selected', track)
                }
            }
        },
        nextSong: function(playing) {
            let next = this.currentSong + 1;
            this.trackSelected(next, playing);
        },
        prevSong: function(playing) {
            let next = this.currentSong - 1;
            this.trackSelected(next, playing);
        },
        initPlaylist: function() {
            //this.trackSelected(0, false);
        },
        toggleFullMobilePlayer: function() {
            if (this.mobile) {
                //console.log('opening...')
                if (this.fullMobilePlayer) {
                    this.fullMobilePlayer = false
                }  else {
                    this.fullMobilePlayer = true;

                }

                this.$refs['playlist'].classList.toggle('wide-open');

                if (!this.inapp.isInApp) {
                    this.$refs['playlist'].classList.toggle('big-pad')
                }
            }
        },
        embedTime: function() {
            this.$emit('load-list', this.$route.params.uniq);
            this.orientation = this.$route.query.orientation;

            if (this.orientation == 'landscape') {
                this.$refs['playlist'].classList.add('player-embed-landscape');
            } else if (this.orientation == 'portrait') {
                this.$refs['playlist'].classList.add('player-embed-portrait');
                this.$refs['playlist'].classList.add('scroll');
            }
        }
    },
    watch: {
        list: function() {
            this.selected = {};
            this.trackSelected(-1, false);
        },
        toPlay: function() {
            if (this.toPlay !== -1 || this.toPlay !== undefined) {
                this.trackSelected(this.toPlay, true);
            } else {
                if (this.list.length > 0) {
                    this.trackSelected(-1, false);
                }
            }
        },
        state: function() {
            if (this.state == 'playlist') {
                this.playerMode = true;
            } else if (this.state == 'embed') {
                this.embedTime()
            } else {
                this.playerMode = false
            }
        }
    },
    computed: {
        cssVars: function() {
            return {
                '--primary': this.colors.primary,
                '--secondary': this.colors.secondary
            }
        }
    },
    mounted() {
        if (this.state == 'embed') {
            this.embedTime();
        }
    },
    data() {
        return {
            previewMode: this.preview,
            albumCover: '',
            selected: {},
            currentSong: 0,
            fullMobilePlayer: false,
            playerMode: false,
            inapp: new InApp(navigator.userAgent || navigator.vendor || window.opera),
            orientation: ''
        }
    }
}
</script>