<template>
    <div class="track-entry">
        <div v-if="!fetching" class="track-input">
            <input v-model="url" placeholder="Type or Paste a Bandcamp track URL here.">
            <a @click="getSong">add track</a>
        </div>
        <div v-else-if="fetching" class="track-searching">
            Searching...
        </div>
    </div>
</template>

<style lang="scss">
    .track-entry {
        background-color: white;
        border-color: 1px solid white;
        padding: 1rem;
        width: 100%;

        .track-input {
            align-items: center;
            display: flex;
            justify-content: space-between;

            input {
                width: 75%;
            }

            a:hover {
                cursor: pointer;
            }

            div {
                text-align: center;
                width: 25%;
            }

            a {
                background-color: black;
                border: 1px solid black;
                color: white;
                padding: 0.5rem;
                line-height: 1.666;
                text-align: center;
                width: 25%;
            }

            span:hover {
                background-color: black;
                color: white;
                cursor: pointer;
            }
        }
    }

    @media (max-width: 1024px) {
        .track-entry {

            .track-input {
                display: flex;
                flex-direction: column;

                input {
                    width: 100%;
                    margin-bottom: 1rem;
                }

                a {
                    width: 100%;
                }
            }
        }
    }
</style>

<script>
import axios from 'axios';

export default {
    name: 'SongEntry',
    props: ['primaryColor', 'secondaryColor'],
    methods: {
        getSong: function() {
            this.fetching = true;

            axios.post('https://bndcmpr.co/api', {url: this.url})
            .then(res => {
                this.$emit('playlist-add-song', res.data);
                this.url = '';
                this.fetching = false;
            });
        }
    },
    data() {
        return {
            fetching: false,
            url: null,
            song: null,
        }
    }
}
</script>